* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.main {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.main, .topBar {
  position: relative;
  z-index: 1;
}

.zapButton {
  position: fixed;
  bottom: 12vh;
  right: 5px;
  z-index: 2;
  height: 5vh;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 1200px;
  background-image: url('./assets/capaHD.jpeg');
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
  clip-path: ellipse(100% 52% at 50% 45%);
  background-repeat: no-repeat;
  z-index: -1;
}

.topBar {
  width: 100vw;
  padding: 10px 10% 1px 10%;
  background-color: white;
}

.topBar>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 82px;
}

.desktopMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  gap: 20px;
}

.desktopMenu>a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.topBar>div>a:hover {
  color: #3A87B8;
  text-decoration: underline;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px 8px 20px;
  height: 48px;
  border-radius: 25px;
  background: linear-gradient(270.95deg, #3A87B8 0%, #0B659E 100%);
  box-shadow: 0px 6px 8px 0px #8688F233;
  color: black;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.logo {
  width: 80px;
}

.slider {
  width: 100vw;
  position: relative;
  padding-bottom: 20px;
}

.header-text {
  height: 1000px;
  width: 100vw;
  padding: 0 10%;
}

.typografia {
  padding-top: 8vh;
  color: #ffffff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
  font-weight: bold !important;
  text-align: left;
  font-size: 2.5vw !important;
}

.phrase {
  width: 350px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
  font-size: 1.3rem;
  font-weight: normal !important;
}

.box-reel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
  padding: 0 10% 0 10%;
  gap: 20px;
}

.reel-container {
  position: relative;
  display: inline-block;
  transition: transform 0.3s ease;
}
.reel-container:hover {
  cursor: pointer;
  transform: scale(1.08);
}
.reel-container:hover .icon {
  color: red;
}

.reel {
  width: 350px;
  border-radius: 20px;
  display: block;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 100px !important;
}


.card {
  height: 11vh;
  width: 15vw;
  border-radius: 20% !important;
}

.card>img {
  background-position: center;
  background-size: cover;
  height: 100%;
}

.searchBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 1% 10% 0 10%;
  background-color: #ffffff;
}

.gallery {
  display: flex;
  width: 100vw;
  padding: 1% 10% 5% 10%;
  justify-content: center;
  background-color: #ffffff;
  flex-direction: column;
}
.customTimePicker .MuiOutlinedInput-notchedOutline {
  border-radius: 20px !important;
}

.avatarContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  background: #00000020;
  padding: 5px;
  border-radius: 30px;
}

.avatarContainer>p {
  margin: 0 5px 0 5px
}

.footer {
  display: flex;
  align-items: center;
  width: 100vw;
  height: min-141px;
  background: #000000C9;
  padding: 20px 0 0 0;
}

.navigation-box {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.filter-text-title {
  font-size: 3.5vw;
  font-weight: bold;
}
.filter-text-subtitle {
  font-size: 1.5vw;
}
.filter-text-hashtag {
  font-size: 1.5vw;
  font-weight: bold;
}
.no-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 10%	0 0 0;
  cursor: pointer;
  background-color: #ffffff;
}.clearSearch {
  cursor: pointer;
  margin-top: 2vh;
}

.clearSearch:hover {
  color: #3A87B8;
  scale: 1.1;
}



@media (max-width: 768px) {

  .typografia {
    padding-top: 8vh;
    color: #ffffff;
    text-shadow: none;
    font-weight: bold !important;
    text-align: left;
    font-size: 5vw !important;
  }

  .phrase {
    width: 350px;
    font-size: 1.2rem;
    font-weight: bold !important;
    text-shadow: none;
  }
  .filter-text-title {
    font-size: 3.8vw;
    font-weight: bold;
  }
  .filter-text-subtitle {
    font-size: 3.5vw;
  }
  .filter-text-hashtag {
    font-size: 3.5vw;
    font-weight: bold;
  }
}

@media (max-width: 1100px) {
  .desktopMenu {
    display: none;
  }
  .reel {
    width: 180px;
    border-radius: 20px;
  }

  .box-reel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
    padding: 0 2% 0 2%;
    gap: 5px;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 80px !important;
  }
}

@media (min-width: 1100px) {
  .hamburguer {
    display: none;
  }

  .header-text {
    height: 580px;
    width: 100vw;
    padding: 0 10%;
  }

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 800px;
    background-image: url('./assets/capaHD.jpeg');
    background-size: cover;
    background-position: bottom;
    background-attachment: fixed;
    clip-path: ellipse(100% 52% at 50% 45%);
    background-repeat: no-repeat;
    z-index: -1;
  }
}

@media (min-width: 1440px) {
  .reel {
    width: 250px;
    border-radius: 20px;
  }
}

@media (min-width: 1920px) {
  .reel {
    width: 250px;
    border-radius: 20px;
  }

  .background {
    height: 1000px; 
    clip-path: ellipse(100% 60% at 50% 40%); 
  }

  .header-text {
    height: 800px; 
    width: 100vw;
    padding: 0 15%; 
  }

  .typografia {
    padding-top: 9vh;
    color: #ffffff;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
    font-weight: bold !important;
    text-align: left;
    font-size: 3vw !important;
  }
}


@media (min-width: 3000px) {
  .header-text {
    height: 900px;
    width: 100vw;
    padding: 0 15%; 
  }
  .typografia {
    padding-top: 8vh;
    color: #ffffff;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
    font-weight: bold !important;
    text-align: left;
    font-size: 3vw !important;
  }

  .background {
    height: 1150px; 
    clip-path: ellipse(100% 60% at 50% 40%);
  }

  .box-reel {
    padding: 0 5%; 
    gap: 30px;
  }
}